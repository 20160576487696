:root {
    --primary-color: var(--p-primary-color);
    --color-green-arquea: #70ba6d;
    --color-green-arquea-200: #81c49d;
    --color-green-arquea-300: #9fd1b4;
    --color-green-arquea-400: #bbdecd;
    --color-green-arquea-500: #c8e4d7;
    --color-green-arquea-600: #d4ebe2;
    --color-green-arquea-700: #e1f2ec;
    --color-green-arquea-800: #f0fbf8;
}
