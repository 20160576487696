html {
  height: 100%;
  font-size: 14px
}

body {
  font-family: "Inter", sans-serif;
  color: var(--text-color);
  background-color: var(--surface-ground);
}

a {
  text-decoration: none;
}
