@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/inter-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BarlowCondensed';
  src: url('assets/fonts/barlow-condensed.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
.highlight {
  font-family: "BarlowCondensed", sans-serif;
}